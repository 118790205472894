/* eslint-disable @typescript-eslint/no-magic-numbers */
/* eslint-disable import/exports-last, @typescript-eslint/naming-convention */
import { PageViewsLoyaltyService } from '@portal/user-loyalty';
import { guardEmptyString, guardUnspecified } from '@portal/utils/util-guards';
import { sendYM, sendLI, sendMA, sendGA } from '@smh/analytics/core';
import { Regions } from '@smh/projects/regions';

import {
  BRANCHLESS_REGIONS_WITHOUT_SOCHI,
  PAGE_NAME,
  PagesType
} from '@jtnews/shared/data';

import {
  ExternalScriptsService,
  externalScriptsService
} from '../external-scripts.service';
import type { MediascopeRequiredParams } from '../mediascope-analytics';
// TODO: REGIONNEWS-31874 перенести mediascope в @smh/analytics/core
import { MediascopeAnalyticsService } from '../mediascope-analytics';

export type CountersMap<T> = { [regionId in Regions]?: T };

export type RecordData = {
  recordId: number;
  type: '' | 'Новость' | 'Статья' | 'Спецпроект';
  /**
   * Дата в RFC-822 Date and Time Specification
   * Пример: "Fri, 09 Feb 2018 12:39:12 GMT"
   */
  publicationDate: string;
  /**
   * Категории материала. Если коммерческий материал, то ['РЕКЛАМА'],
   * иначе рубрики материала.
   */
  categories: string[];
  /**
   * Массив авторов материала.
   */
  authors: string[];
  /**
   * Массив форматов материала.
   */
  formats: [{ id: number; name: string; url: string }];
  /**
   * Заголовок материала
   */
  header: string;
};

export type PageData = {
  /**
   * Путь с хостом + query-параметры (window.location.href).
   * Пример: "https://example.com/non-article-url-actual?utm_source=example"
   */
  href: string;
  /**
   * Пусть без хоста и query-параметров (window.location.pathname).
   * Пример: "/non-article-url-actual"
   */
  pathname: string;
  /**
   * Query-параметры в урле (window.location.search).
   * Пример: "?utm_source=example"
   */
  search: string;
  /**
   * Каноникал без query-параметров
   * Пример: "https://example.com/non-article-url-actual"
   */
  canonicalUrl: string;
  /**
   * Заголовок страницы (document.title)
   */
  title: string;
  /**
   * Пример: "ru", "en" и т.д.
   */
  language: string;
  type: string;
  recordData?: RecordData;
};

export type YandexReachGoalData = {
  url: string;
  title: string;
};

export type YandexHitData = {
  title: string;
};

export type GAParams = {
  category?: string;
  action?: string;
  label?: string;
  value?: number;
};

const CHIRK_IDS = [75, 138];

export class AnalyticsService {
  public static readonly mailCounters: CountersMap<string[]> = {
    66: ['66268'],
    54: ['3059456'],
    52: ['20148'],
    61: ['2610772'],
    74: ['3100437'],
    24: ['2604769'],
    2: ['3118390'],
    34: ['3118391'],
    29: ['3118393'],
    45: ['3118394'],
    59: ['3118395'],
    63: ['3118396'],
    72: ['3118392'],
    76: ['3118397']
  };

  public static readonly yandexCounters: CountersMap<number[]> = {
    2: [12725830, 29841809, 64713388],
    14: [24666170, 64713388, 94833164],
    16: [12725806, 64713388],
    18: [24666170, 64713388, 94832027],
    22: [1337751, 64713388],
    23: [69554911, 64713388],
    24: [1124739, 55125172, 64713388],
    25: [93432417, 64713388],
    26: [24666170, 64713388, 94833034],
    29: [12725860, 46680615, 64713388],
    34: [12725839, 29841789, 64713388],
    35: [57002071, 24666170, 64713388, 94831911],
    36: [24666170, 64713388, 94831957],
    38: [24666170, 24840500, 64713388],
    42: [1337755, 64713388],
    43: [57002071, 24666170, 64713388, 94832081],
    45: [49999966, 55125214, 64713388],
    47: [57002071, 64713388, 94833187],
    48: [57002071, 24666170, 64713388, 94832516],
    51: [24666170, 64713388, 94832563],
    52: [9046624, 55125160, 64713388],
    53: [57002071, 24666170, 64713388, 94831799],
    54: [1337757, 51049001, 64713388],
    55: [1337747, 55125190, 64713388],
    56: [24666170, 64713388, 94832630],
    59: [6775807, 46680585, 64713388],
    60: [57002071, 24666170, 64713388, 94832846],
    61: [12725824, 46680621, 64713388],
    62: [24666170, 64713388, 24570965],
    63: [12725662, 28955235, 64713388],
    64: [24666170, 64713388, 94832970],
    66: [6874273, 53777275, 64713388],
    68: [57002071, 24666170, 64713388, 94833106],
    70: [1349065, 64713388],
    71: [24666170, 64713388, 94833125],
    72: [12725777, 46680579, 64713388],
    73: [95082668, 64713388],
    74: [6596923, 46680558, 64713388],
    75: [21438901, 64713388],
    76: [12725875, 46680597, 64713388],
    78: [64713388],
    86: [69555148, 64713388],
    89: [57002071, 24666170, 64713388, 94832930],
    93: [83432182, 64713388],
    124: [1124739, 55125172, 64713388],
    142: [57002071, 24666170, 64713388],
    154: [1337757, 51049001, 64713388],
    155: [1337747, 64713388],
    166: [6874273, 53777275, 64713388],
    170: [57002071, 24666170, 64713388],
    1077: [84217165, 64713388],
    1154: [96511210],
    114160: [57002071, 24666170, 64713388, 94833075],
    142982: [73089292, 64713388],
    182028: [79495999, 24666170, 64713388]
  };

  public static readonly smiLiveInternetCounters: CountersMap<string[]> = {
    2: ['hsdigital/rn/smi/02', 'rugion', '02'],
    14: ['hsdigital/rn/smi/14', 'rugion'],
    16: ['hsdigital/rn/smi/16', 'rugion'],
    18: ['hsdigital/rn/smi/18', 'rugion'],
    22: ['hsdigital/rn/smi/22', 'rugion'],
    23: ['hsdigital/rn/smi/23', 'rugion'],
    24: ['hsdigital/rn/smi/24', 'ngs24'],
    25: ['hsdigital/rn/smi/25'],
    26: ['hsdigital/rn/smi/26', 'rugion'],
    29: ['hsdigital/rn/smi/29', 'rugion', 'r29'],
    34: ['hsdigital/rn/smi/34', 'rugion', '34'],
    35: ['hsdigital/rn/smi/35', 'rugion'],
    36: ['hsdigital/rn/smi/36', 'rugion'],
    38: ['hsdigital/rn/smi/138', 'ircity'],
    42: ['hsdigital/rn/smi/42', 'rugion'],
    43: ['hsdigital/rn/smi/43', 'rugion'],
    45: ['hsdigital/rn/smi/45', 'rugion', 'r45'],
    47: ['hsdigital/rn/smi/78', 'rugion'],
    48: ['hsdigital/rn/smi/48', 'rugion'],
    51: ['hsdigital/rn/smi/51', 'rugion'],
    52: ['hsdigital/rn/smi/52', 'nnru'],
    54: ['hsdigital/rn/smi/54/news', 'ngs_ru'],
    55: ['hsdigital/rn/smi/55', 'ngs55'],
    56: ['hsdigital/rn/smi/56', 'rugion'],
    59: ['hsdigital/rn/smi/59', 'rugion', '59'],
    60: ['hsdigital/rn/smi/60', 'rugion'],
    61: ['hsdigital/rn/smi/61', 'rugion', '61'],
    62: ['hsdigital/rn/smi/62', 'rugion'],
    63: ['hsdigital/rn/smi/63', 'rugion', '63'],
    64: ['hsdigital/rn/smi/64', 'rugion'],
    66: ['hsdigital/rn/smi/66', 'e1_ru'],
    68: ['hsdigital/rn/smi/68', 'rugion'],
    70: ['hsdigital/rn/smi/70', 'rugion'],
    71: ['hsdigital/rn/smi/71', 'rugion'],
    72: ['hsdigital/rn/smi/72', 'rugion', '72'],
    73: ['hsdigital/rn/smi/73', 'rugion'],
    74: ['hsdigital/rn/smi/74', 'rugion', '74'],
    75: ['hsdigital/rn/smi/75', 'wwwchitaru'],
    76: ['hsdigital/rn/smi/76', 'rugion', 'r76'],
    86: ['hsdigital/rn/smi/86', 'rugion'],
    89: ['hsdigital/rn/smi/89', 'rugion'],
    93: ['hsdigital/rn/smi/sochi/'],
    124: ['hsdigital/rn/smi/24', 'ngs24'],
    142: ['hsdigital/rn/smi/42', 'rugion'],
    154: ['hsdigital/rn/smi/54', 'rugion'],
    155: ['hsdigital/rn/smi/55', 'rugion'],
    166: ['hsdigital/rn/smi/66', 'rugion'],
    170: ['hsdigital/rn/smi/70', 'rugion'],
    1077: ['hsdigital/rn/smi/99', 'rugion'],
    114160: ['hsdigital/rn/smi/sterlitamak', 'rugion'],
    142982: ['hsdigital/rn/smi/163', 'rugion'],
    182028: ['hsdigital/rn/smi/mgorsk', 'rugion']
  };

  public static readonly mediascopeCounters: CountersMap<MediascopeRequiredParams> = {
    66: {
      idc: '160',
      cid: 'e1_ru',
      tms: 'e1_ru',
      account: 'e1_ru',
      tmsec: 'mx3_e1_ru'
    }
  };

  public static readonly googleCounters: CountersMap<string> = {
    ['2']: 'G-C53H9Q0MJS',
    ['14']: 'G-9Y8Z56E8X3',
    ['16']: 'G-Z293NV5Z8B',
    ['18']: 'G-TSLP1957GD',
    ['22']: 'G-83Q3MG4D7D',
    ['23']: 'G-JH47QVRQN1',
    ['24']: 'G-2Z6L31N7DW',
    ['25']: 'G-N6K338JL92',
    ['26']: 'G-JVPDCHJZLM',
    ['29']: 'G-6W9E3PLVQ4',
    ['34']: 'G-D55XSKWF8D',
    ['35']: 'G-ZTW8DX8XHK',
    ['36']: 'G-1K8PSHV4GT',
    ['38']: 'G-LHMRY97VLC',
    ['42']: 'G-3NYWCTB96W',
    ['43']: 'G-D24R56C5ZV',
    ['45']: 'G-ETV7L9B3HX',
    ['47']: 'G-TCMR42EMJP',
    ['48']: 'G-XPKCPHQK9M',
    ['51']: 'G-5GQKZ8J4DD',
    ['52']: 'G-TRSEKWR47P',
    ['53']: 'G-SR4RMKHCGQ',
    ['54']: 'G-KRPLCP05GH',
    ['55']: 'G-6VCVY71S53',
    ['56']: 'G-8CYTESEHEF',
    ['59']: 'G-ZMJVMFEN2Q',
    ['60']: 'G-CVT6NQFTWF',
    ['61']: 'G-2VP0KJM9WT',
    ['62']: 'G-XNTVNR6QJT',
    ['63']: 'G-2SBVJDM5HV',
    ['64']: 'G-4XV287SG8X',
    ['66']: 'G-SQJGELYJPJ',
    ['68']: 'G-DT546VLZPJ',
    ['70']: 'G-DCGN4KE972',
    ['71']: 'G-V3WE3JX6W1',
    ['72']: 'G-TK1KMBBNBD',
    ['73']: 'G-MMK3ZL60HT',
    ['74']: 'G-6NGXN1WYLE',
    ['75']: 'G-L18HS6234X',
    ['76']: 'G-CL6WLKYZD6',
    ['78']: '',
    ['86']: 'G-DW8PCBQG3Z',
    ['89']: 'G-G8MF5BX35R',
    ['93']: 'G-MSGRKYL6M9',
    ['124']: 'G-2Z6L31N7DW',
    ['142']: 'G-3NYWCTB96W',
    ['154']: 'G-KRPLCP05GH',
    ['155']: 'G-6VCVY71S53',
    ['166']: 'G-SQJGELYJPJ',
    ['170']: 'G-DCGN4KE972',
    ['1077']: 'G-YVBS88P86E',
    ['1154']: 'G-8M8SHZ4L5D',
    ['114160']: 'G-S97HDNND7V',
    ['142982']: 'G-YW4GBKKTDL',
    ['182028']: 'G-XS3T8KHKJ3'
  };

  private _isSentFirstAnalyticsData = false;

  private readonly _mediascopeMetricsService = new MediascopeAnalyticsService();

  private readonly _pageViewsLoyaltyService = new PageViewsLoyaltyService();

  private readonly _externalScriptsService: ExternalScriptsService =
    externalScriptsService;

  public sendPageAnalyticsData(
    regionId: Regions,
    pageData: PageData,
    serverDate: string
  ): void {
    if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test') {
      this._sendPageAnalyticsDataToYandex(regionId, pageData);

      if (!BRANCHLESS_REGIONS_WITHOUT_SOCHI.includes(regionId)) {
        this._sendPageAnalyticsDataToMail(regionId, pageData);
      }

      this._isSentFirstAnalyticsData = true;

      if (guardUnspecified(pageData.recordData)) {
        const { href } = window.location;

        const hrefEncoded = encodeURIComponent(href);

        this._sendMediascopeEventMetrics(
          regionId,
          pageData.recordData.recordId,
          hrefEncoded
        );
      }

      this._sendMediascopeAuditoryMetrics(regionId);

      if (regionId !== 61) {
        const counters = AnalyticsService.smiLiveInternetCounters[regionId];
        this._sendAnalyticsDataToLI(counters);
      }

      this._sendGAPageView(regionId);

      if (CHIRK_IDS.includes(regionId)) {
        this.sendReachGoal(regionId, 'new_design');
      }

      this._sendUserLoyaltyAnalytics(serverDate, regionId);
    }
  }

  public sendReachGoal(
    regionId: Regions,
    goalName: string,
    reachGoalParams?: unknown
  ): void {
    const counters = AnalyticsService.yandexCounters[regionId];
    if (this._guardCounter(counters)) {
      sendYM('reachGoal', { counters, goalName, params: reachGoalParams });
    }
  }

  public sendNewReachGoal(
    regionId: Regions,
    pageType: PagesType,
    blockType: string,
    fieldType: string,
    valueName: unknown,
    goalName: string,
    productName = 'Продукт'
  ): void {
    const counters = AnalyticsService.yandexCounters[regionId] ?? [];
    if (this._guardCounter(counters)) {
      const block = guardUnspecified(blockType)
        ? {
            [blockType]: {
              [fieldType]: valueName
            }
          }
        : {
            [fieldType]: valueName
          };

      const params = {
        [productName]: {
          [PAGE_NAME[pageType]]: block
        }
      };
      sendYM('reachGoal', {
        counters,
        goalName,
        params
      });
    }
  }

  public sendNewFormatReachGoal(
    regionId: Regions,
    goalName: string,
    pageType: PagesType,
    blockType: string,
    actionType: string,
    prop1?: string,
    prop2?: unknown
  ): void {
    const counters = AnalyticsService.yandexCounters[regionId] ?? [];
    if (this._guardCounter(counters)) {
      const params = {
        [PAGE_NAME[pageType]]: {
          [blockType]: guardUnspecified(prop1)
            ? {
                [actionType]: guardUnspecified(prop2)
                  ? {
                      [prop1]: prop2
                    }
                  : prop1.toString()
              }
            : actionType
        }
      };

      sendYM('reachGoal', {
        counters,
        goalName,
        params
      });
    }
  }

  public sendUserParams(regionId: Regions, params: unknown): void {
    const counters = AnalyticsService.yandexCounters[regionId];
    if (this._guardCounter(counters)) {
      sendYM('userParams', { counters, params });
    }
  }

  private _sendAnalyticsDataToLI(counters?: string[]) {
    if (this._guardCounter(counters)) {
      counters.forEach(counterId => {
        sendLI('hit', { counterId });
      });
    }
  }

  private _sendGAPageView(regionId: Regions) {
    const counter = AnalyticsService.googleCounters[regionId];

    if (guardUnspecified(counter) && guardEmptyString(counter)) {
      sendGA('pageView', { trackers: [counter] });
    }
  }

  private _sendMediascopeEventMetrics(
    regionId: Regions,
    recordId: number,
    urlc: string
  ): void {
    const counterParams = AnalyticsService.mediascopeCounters[regionId];
    if (counterParams !== undefined) {
      this._mediascopeMetricsService.fetchCounter(counterParams, recordId, urlc);
    }
  }

  private _sendMediascopeAuditoryMetrics(regionId: Regions): void {
    const counterParams = AnalyticsService.mediascopeCounters[regionId];
    if (counterParams !== undefined) {
      void this._externalScriptsService.injectScriptToHead({
        content: this._mediascopeMetricsService.getAuditoryCounterScript(counterParams),
        required: true
      });
    }
  }

  private _sendPageAnalyticsDataToYandex(regionId: Regions, pageData: PageData) {
    if (this._isSentFirstAnalyticsData) {
      // при первом заходе не нужно слать hit, потому что
      // это делается при инициализации счетчика
      const url = `${pageData.pathname}${pageData.search}`;
      const params = {
        title: pageData.title
      };

      const counters = AnalyticsService.yandexCounters[regionId];
      if (this._guardCounter(counters)) {
        sendYM('hit', { counters, url, params });
      }
    }
  }

  private _sendPageAnalyticsDataToMail(regionId: Regions, pageData: PageData) {
    if (this._isSentFirstAnalyticsData) {
      const url = `${pageData.pathname}${pageData.search}`;
      const counters = AnalyticsService.mailCounters[regionId];

      if (this._guardCounter(counters)) {
        counters.forEach(counterId => {
          sendMA('pageView', { counterId, url });
        });
      }
    }
  }

  private _sendUserLoyaltyAnalytics(date: string, regionId: Regions) {
    const { loyalty, views } = this._pageViewsLoyaltyService.getPageViewsLoyalty(date);

    const params = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Пользователь: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Лояльность (просмотры за 30 дней)': { [loyalty]: views.toString() }
      }
    };

    this.sendUserParams(regionId, params);
  }

  private _guardCounter<C extends unknown[]>(
    counters?: C | undefined
  ): counters is NonNullable<C> {
    return counters !== undefined && counters.length > 0;
  }
}

export const analyticsService = new AnalyticsService();
