/* eslint-disable @typescript-eslint/no-magic-numbers */
import { Regions } from '@jtnews/shared/seedwork/frontend/domain';

type CountersMap<T> = { [regionId in Regions]?: T };

const YANDEX_COUNTERS_IDS: CountersMap<number[]> = {
  ['2']: [12725830, 29841809, 64713388],
  ['14']: [24666170, 64713388, 94833164],
  ['16']: [12725806, 64713388],
  ['18']: [24666170, 64713388, 94832027],
  ['22']: [1337751, 64713388],
  ['23']: [69554911, 64713388],
  ['24']: [1124739, 55125172, 64713388],
  ['25']: [93432417, 64713388],
  ['26']: [24666170, 64713388, 94833034],
  ['29']: [12725860, 46680615, 64713388],
  ['34']: [12725839, 29841789, 64713388],
  ['35']: [57002071, 24666170, 64713388, 94831911],
  ['36']: [24666170, 64713388, 94831957],
  ['38']: [24666170, 24840500, 64713388],
  ['42']: [1337755, 64713388],
  ['43']: [57002071, 24666170, 64713388, 94832081],
  ['45']: [49999966, 55125214, 64713388],
  ['47']: [57002071, 64713388, 94833187],
  ['48']: [57002071, 24666170, 64713388, 94832516],
  ['51']: [24666170, 64713388, 94832563],
  ['52']: [9046624, 55125160, 64713388],
  ['53']: [57002071, 24666170, 64713388, 94831799],
  ['54']: [1337757, 51049001, 64713388],
  ['55']: [1337747, 55125190, 64713388],
  ['56']: [24666170, 64713388, 94832630],
  ['59']: [6775807, 46680585, 64713388],
  ['60']: [57002071, 24666170, 64713388, 94832846],
  ['61']: [12725824, 46680621, 64713388],
  ['62']: [24666170, 64713388, 24570965],
  ['63']: [12725662, 28955235, 64713388],
  ['64']: [24666170, 64713388, 94832970],
  ['66']: [6874273, 53777275, 64713388],
  ['68']: [57002071, 24666170, 64713388, 94833106],
  ['70']: [1349065, 64713388],
  ['71']: [24666170, 64713388, 94833125],
  ['72']: [12725777, 46680579, 64713388],
  ['73']: [95082668, 64713388],
  ['74']: [6596923, 46680558, 64713388],
  ['75']: [21438901, 64713388],
  ['76']: [12725875, 46680597, 64713388],
  ['78']: [64713388],
  ['86']: [69555148, 64713388],
  ['89']: [57002071, 24666170, 64713388, 94832930],
  ['93']: [83432182, 64713388],
  ['124']: [1124739, 55125172, 64713388],
  ['142']: [57002071, 24666170, 64713388],
  ['154']: [1337757, 51049001, 64713388],
  ['155']: [1337747, 64713388],
  ['166']: [6874273, 53777275, 64713388],
  ['170']: [57002071, 24666170, 64713388],
  ['1077']: [84217165, 64713388],
  ['1154']: [96511210],
  ['114160']: [57002071, 24666170, 64713388, 94833075],
  ['142982']: [73089292, 64713388],
  ['182028']: [79495999, 24666170, 64713388],
};

export const getYandexCountersIds = (input: { regionId: Regions }): number[] => {
  const { regionId } = input;

  return YANDEX_COUNTERS_IDS[regionId] ?? [];
};
